<template>
  <!-- 填写收获地址 -->
  <section class="fill-mask">
    <div class="fill-main">
      <div class="field-box">
        <p>姓名</p>
        <div :class="['input-box', isDisable ? 'disable' : '']">
          <input :disabled="isDisable" placeholder="请输入姓名" type="text" v-model="form.name" />
        </div>
      </div>
      <div class="field-box">
        <p>手机号码</p>
        <div :class="['input-box', isDisable ? 'disable' : '']">
          <input :disabled="isDisable" placeholder="请输入您的手机号码" type="text" v-model="form.mobile" />
        </div>
      </div>
      <div class="field-box">
        <p>收货地址</p>
        <div :class="['input-box', isDisable ? 'disable' : '']">
          <input :disabled="isDisable" placeholder="请输入您的收货地址" type="text" v-model="form.address" />
        </div>
      </div>
      <p class="tips">请您正确填写以上信息，如因信息填写错误造成投递错误或失败，责任由填写者承担。奖品将在活动结束后10个工作日内陆续寄出，请耐心等待。</p>
      <img class="confirm-btn" src="/confirm-btn.png" @click="handleConfirm" v-if="!isDisable" />
      <img v-else class="iknow-btn" src="/iknow.png" @click="handleClose" />
    </div>
    <img class="close-btn" @click="handleClose" src="/close-btn.png" alt="" />
  </section>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      form: {
        name: "",
        mobile: "",
        address: "",
      },
      isDisable: false
    };
  },
  async mounted() {
    const res = await api.getAddress()
    if (res.result) {
      res.msg && (this.form = res.msg)
      this.isDisable = !!this.form.name
    }
  },
  methods: {
    async handleConfirm() {
      if (!this.form.name) {
        this.$toast('请输入姓名')
        return false
      }
      if (!this.form.mobile) {
        this.$toast('请输入您的手机号码')
        return false
      }
      if (!this.form.address) {
        this.$toast('请输入您的收货地址')
        return false
      }
      const res = await api.info(this.form);
      console.log(res);
      if (res.msg) {
        this.$toast(res.msg)
      }
      this.$emit("close");
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.fill-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;
}
.fill-main {
  width: 658px;
  height: 1000px;
  margin: 0 auto;
  margin-top: 150px;
  background-image: url("/address.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 230px;
}
.field-box {
  margin-bottom: 32px;
}
.input-box {
  padding: 14px 24px;
  height: 67px;
  width: 504px;
  border-radius: 12px;
  background: #FFF;
  box-sizing: border-box;
  margin: 0 auto;
}
.field-box p {
  margin: 0 auto;
  width: 504px;
  color: #545964;
  text-align: left;
  font-family: "PingFang SC";
  font-size: 28px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 16px;
}
.input-box input {
  width: 100%;
  height: 100%;
}
.input-box.disable {
  background: #D3E2FF;
}
.input-box.disable input {
  background: #D3E2FF;
  color: #86909C;
}

input::placeholder {
  color: #c9cdd4; /* 灰色的占位符文本 */
}
.tips {
  width: 504px;
  margin: 0 auto;
  color: #828896;
  text-align: justify;
  font-family: "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}
.confirm-btn {
  margin: 0 auto;
  height: 114px;
  width: 336px;
  margin-top: 35px;
  
}
.iknow-btn {
  margin: 0 auto;
  height: 114px;
  width: 336px;
  margin-top: 35px;
  
}
</style>
