<template>
  <div class="main">
    <!-- <video id="prev1" src="/v1.mp4" playsinline></video> -->
    <!-- <video id="prev2" src="/v2.mp4" playsinline ></video> -->
    <!-- loading -->
    <section class="page-loading" v-show="isLoading">
      <span>{{ loadingNum }}%</span>
      <img src="/loading.png" alt="" />
    </section>
    <!--  -->
    <section class="page-content" v-show="!isLoading">
      <!-- 抽奖主界面 -->
      <section class="page-draw">
        <img class="index-bg" src="/index-1.jpg" alt="" />
        <img class="index-bg" src="/index-2.png" alt="" />
        <img class="index-bg" src="/index-3.jpg" alt="" />
        <!-- 按钮定位 -->
        <img @click="isRule = true" class="qiqiu-img" src="/qiqiu.png" alt="" />
        <div class="rest-nums">剩余{{restNums}}次抽卡机会</div>
        <img @click="$router.push('/card-list')" class="mycard-img" src="/my-card.png" alt="" />
        <img @click="isShare = true" class="getnum-img" src="/get-num-btn.png" alt="" />
        <img class="cat-img" @click="toUrl" src="/cat.png" alt="" />
        <div class="draw-btn" @click="handleDraw"></div>
      </section>
      <!-- 抽奖动画1 -->
      <video class="video" v-show="isShowV1" id="v1" src="/v1.mp4" playsinline preload="auto"></video>
      <!-- 抽奖动画2 -->
      <video class="video" v-show="isShowV2" id="v2" src="/v2.mp4?v2" playsinline loop preload="auto"></video>
      <!-- 抽奖结果 -->
      <CardModal v-if="isShowCard" :cardInfo="cardInfo" @close="handleCloseCardModal" @fill="handleFill" />
      <!-- 引导分享蒙层 -->
      <section class="modal-mask" v-show="isShare">
        <img class="share-img" @click="isShare = false" src="/share-mask.png" alt="" />
      </section>
      <!-- 填写收获地址 -->
      <FillAddress v-if="isFill" @close="handleCloseFill" />
      <!-- 活动规则 -->
      <section class="modal-mask" v-show="isRule">
        <img class="rule-img" src="/rule.png?v1" alt="" />
        <div class="rule-close-btn" @click="isRule = false"></div>
        <div class="rule-btn" @click="isRule = false"></div>
      </section>
    </section>
    <audio id="a1" src="/bg-1.MP3" loop v-show="false"></audio>
    <audio id="a2" src="/bg-pt.MP3" v-show="false"></audio>
    <audio id="a3" src="/bg-yincang.MP3" v-show="false"></audio>
    <audio id="v1bg" src="/v1bg.MP3?v1" v-show="false"></audio>
    <!-- <audio id="v2bg" src="/v2-bg.MP3" v-show="false" loop></audio> -->
  </div>
</template>

<script>
import api from "@/api";
import CardModal from '@/components/CardModal'
import FillAddress from '@/components/FillAddress'
import { wxPlay } from '@/utils'

export default {
  components: {
    CardModal,
    FillAddress
  },
  data() {
    return {
      loadingNum: 0,
      isLoading: true,
      isShowV1: false,
      isShowV2: false,
      isShowCard: false,
      isShare: false,
      isFill: false,
      isRule: false,
      restNums: 0,
      cardInfo: {},
      tk: localStorage.getItem("token"),
      isPrev1Load: false,
    };
  },
  methods: {
    toUrl() {
      window.location.href = "https://m.yicai.com/static/dbredirect/index.html?dbredirect=https%3A%2F%2F90831.activity-42.m.duiba.com.cn%2Fhdtool%2Findex%3Fid%3D270672874168306%26dbnewopen"
    },
    handleFill() {
      this.isFill = true
      this.isShowV2 = false
      // const v2bg = document.getElementById("v2bg");
      // v2bg.pause();
      // v2bg.currentTime = 0;
      this.isShowCard = false
    },
    handleCloseCardModal() {
      this.isShowV2 = false
      // const v2bg = document.getElementById("v2bg");
      // v2bg.pause();
      // v2bg.currentTime = 0;
      this.isShowCard = false
    },
    handleCloseFill() {
      this.isFill = false
    },
    async handleLogin() {
      if (!localStorage.getItem('token')) {
        let res = await api.getToken(this.$route.query.code);
        this.tk = res.msg.token;
        localStorage.setItem("token", res.msg.token);
        const { collectionCompleteTime, ssrTime, quota } = res.msg.userInfo
        const isCanGet = collectionCompleteTime || ssrTime ? 1 : 0
        localStorage.setItem("isCanGet", isCanGet);
        this.restNums = quota;
      }
      await this.handleGetNums()
      // 给分享者加次数
      const tk = localStorage.getItem("shareTk")
      if (tk) {
        await this.handleShare(tk)
      }
    },
    async handleGetNums() {
      const res = await api.nums();
      console.log(res)// {"result":true,"msg":"4"}
      if (res.result) {
        const { collectionCompleteTime, ssrTime, quota } = res.msg
        const isCanGet = collectionCompleteTime || ssrTime ? 1 : 0
        localStorage.setItem("isCanGet", isCanGet);
        this.restNums = quota;
      }
    },
    async handleDraw() {
      const res = await api.draw();
      if (res.result) {
        const { quota, ssr}  = res.msg
        this.restNums = quota;
        this.cardInfo = res.msg
        this.isShowV2 = false;
        this.isShowCard = false;
        this.isShowV1 = true;
        // 播放第一个
        wxPlay('v1bg')
        const v1 = document.getElementById("v1");
        v1.currentTime = 0;
        v1.play();
        this.t1 = setTimeout(() => {
            // 播放第二个
            const v2 = document.getElementById("v2");
            v2.play();
            // wxPlay('v2bg')
            this.isShowV2 = true;
            // 关闭第一个
            this.$nextTick(() => {
              this.isShowV1 = false;
              this.isShowCard = true
            })
            wxPlay(ssr ? 'a3' : 'a2')
            clearTimeout(this.t1);
            // 3.754667
        }, 3.75 * 1000)
      } else {
        this.$toast(res.msg)
      }
    },
    // handleDraw() {
    //   // 播放动画
    //   this.isShowV2 = false;
    //   this.isShowCard = false;
    //   this.isShowV1 = true;
    //   // 播放第一个
    //   const v1 = document.getElementById("v1");
    //   v1.play();
    //   this.t1 = setTimeout(() => {
    //       // 播放第二个
    //       const v2 = document.getElementById("v2");
    //       v2.muted = false;
    //       v2.play();
    //       this.isShowV2 = true;
    //       // 关闭第一个
    //       this.$nextTick(() => {
    //         this.isShowV1 = false;
    //         this.isShowCard = true
    //       })
    //       wxPlay(this.ssr ? 'a3' : 'a2')
    //       clearTimeout(this.t1);
    //       // 3.754667
    //   }, 3.75 * 1000)
    //   api.draw().then(res => {
    //     if (res.result) {
    //       const { quota, ssr}  = res.msg
    //       this.ssr = ssr
    //       this.restNums = quota;
    //       this.cardInfo = res.msg
    //     } else {
    //       this.$toast(res.msg)
    //     }
    //   })

    // },
    // 分享链接进入
    async handleShare(shareToken) {
      const res = await api.share(shareToken);
      console.log(res);
      // {"result":true,"msg":""}
    },
    playA1() {
      if (!this.isPlayBg) {
        wxPlay('a1')
        // 预加载v2
        const v2 = document.getElementById("v2");
        v2.muted = true;
        v2.play();
        // const v1 = document.getElementById("v1");
        // v1.play();
        this.isPlayBg = true
      }
    }
  },
  destroyed() {
    document.removeEventListener('touchstart', this.playA1, false)
  },
  async mounted() {
    // document.addEventListener('DOMContentLoaded', function(){
    //   const v1 = document.getElementById('v1');
    
    //   // 检查视频是否已经加载完成
    //   if (v1.readyState > 2) {
    //     console.log("v1视频已经加载完成");
    //   } else {
    //     v1.addEventListener('loadedmetadata', function(){
    //       console.log("v2视频的元数据已加载，可以播放了");
    //     });
    //   }
    // })
    // 重置第一帧
    const v1 = document.getElementById("v1");
    v1.addEventListener('ended', () => {
      console.log('v1 ended')
      setTimeout(() => {
        v1.currentTime = 0;
      }, 0) 
    })
    // loading
    this.timer = setInterval(() => {
      if (this.loadingNum != 100) {
        this.loadingNum += 4;
      } else {
        document.addEventListener('touchstart', this.playA1, false)
        this.isLoading = false
        clearInterval(this.timer);
      }
    }, 50);
    console.log(window.location.href, "window.location.href");
    // 调试
    if (process.env.NODE_ENV == 'development') {
      return false
    }
    // 微信授权登录
    await this.handleLogin()
    wx.ready(() => {
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: "第一财经“赢Yi为奥”集卡大比拼", // 分享标题
        desc: "集运动卡牌，抽一财限定！", // 分享描述
        link: `https://aivsion.icu?tk=${localStorage.getItem("token")}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "https://aivsion.icu/share.png", // 分享图标
        success: function () {
          // 设置成功
          console.log("wx.ready 设置成功");
        },
      });
    });
  },
};
</script>

<style>
.main {
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
}

.page-loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #00abff;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-loading span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #00abff;
  z-index: 3;
}

.page-loading img {
  width: 221px;
  height: 256px;
  animation: scale 1s ease-in-out infinite;
}

@keyframes scale {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.page-content {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.page-draw {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.index-bg {
  width: 100vw;
  height: auto;
}

.qiqiu-img {
  position: absolute;
  right: 16px;
  top: 300px;
  z-index: 1;
  width: 105px;
  height: 147px;
}

.rest-nums {
  position: absolute;
  right: 20px;
  top: 980px;
  /* width: 184px;
  height: 48px; */
  box-sizing: border-box;
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: rgba(57, 55, 84, 0.70);
  font-size: 20px;
  color: #fff;
}

.mycard-img {
  position: absolute;
  left: 0;
  top: 1020px;
  z-index: 1;
  width: 225px;
}

.getnum-img {
  position: absolute;
  right: 0px;
  top: 1020px;
  z-index: 1;
  width: 225px;
}

.cat-img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1060px;
  z-index: 1;
  width: 200px;
  height: 200px;
}

.draw-btn {
  position: absolute;
  z-index: 1;
  top: 610px;
  left: 50%;
  transform: translateX(-50%);
  width: 420px;
  height: 420px;
  border-radius: 50%;
  /* background: #ccc; */
}

.video {
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
}

#v1 {
  z-index: 2;
}
#v2 {
  z-index: 3;
  /* top: -30px!important; */
}

.modal-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
}

.modal-mask .share-img {
  position: absolute;
  width: 382px;
  height: 624px;
  top: 10px;
  right: 10px;
}

.close-btn {
  position: absolute;
  top: 130px;
  right: 80px;
  width: 60px;
  height: 60px;
}

.modal-mask .rule-img {
  width: 661px;
  height: 1290px;
  margin: 0 auto;
  margin-top: 10px;
}

.rule-close-btn {
  position: absolute;
  top: 50px;
  right: 90px;
  width: 60px;
  height: 60px;
}

.rule-btn {
  position: absolute;
  height: 90px;
  width: 320px;
  top: 1120px;
  left: 50%;
  transform: translateX(-50%);
}
#prev1, #prev2 {
  position: absolute;
  width: 100vw;
  top: 0;
  left: -10000px;
  /* left: 0; */
  /* z-index: 1000 */

}
</style>
