import req from "@/utils/request";

export default {
  getToken: (code) => req({ url: "/wx/code2token",  params: { code }}),
  getSign: (url) => req({ url: `/wx/sign`, params: { url } }),
  nums: () => req({ url: `/quota` }),
  draw: () => req({ url: `/draw` }),
  share: (tk) => req({ url: `/share`, params: { tk } }),
  info: (data) => req({ url: `/info`, method: 'POST', data }),
  getAddress: () => req({ url: `/info`}),
  card: () => req({ url: `/card` }),
};
