<template>
  <section class="draw-res">
    <!-- <video id="v2" loop src="/v2.mp4" playsinline></video> -->
    <section class="card-part">
      <!-- 标题 -->
      <img :style="{ visibility: !cardInfo.fromCard ? 'visible' : 'hidden' }"  class="card-title" :src="titleImg" alt="" />
      <!-- 卡片 -->
      <div class="card-main">
        <img class="card-img" :src="`/card/${cardInfo.cardNum}.jpg?v1`" alt="" />
      </div>
      <img class="long-btn" src="/long-btn.png" />
      <!-- <img @click="handleFill" :style="{ visibility: isShowGetBtn && !cardInfo.fromCard ? 'visible' : 'hidden' }"  class="get-btn" src="/get-btn.png" /> -->
      <img @click="handleFill" :style="{ visibility: 'hidden' }"  class="get-btn" src="/get-btn.png" />
      <img @click="handleClose" class="card-close-btn" src="/close-btn.png" />
    </section>
  </section>
</template>

<script>
export default {
  props: ['cardInfo'],
  data() {
    return {
      isShowV2: true
    };
  },
  computed: {
    isShowGetBtn: function() {
      return (this.cardInfo.collectionComplete && this.cardInfo.collectionCompleteReward) || this.cardInfo.ssr
    },
    titleImg: function () {
      // 抽到隐藏卡
      if (this.cardInfo.ssr) {
        return "/title-1.png"; // 恭喜获得隐藏卡
      } 
      // 收集全
      if (this.cardInfo.collectionComplete ) {
        if (this.cardInfo.collectionCompleteReward) {// 获奖
          return "/title-3.png"; // 恭喜集齐所有卡牌
        } else { // 未获奖 
          return "/title-2.png"; // 恭喜获得
        }
      } 
      return '/title-2.png'
     },
     cardImg: function() {
        return `/card-${this.cardInfo.cardNum}.png`
     }
  },
  methods: {
    handleClose() {
      this.$emit("close");
      this.isShowV2 =  false
    },
    handleFill() {
      this.$emit("fill");
      this.isShowV2 =  false
    },
  }
};
</script>

<style>
.draw-res {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.draw-res video {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
}
.card-part {
  position: absolute;
  z-index: 1002;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-main {
  width: 402px;
  height: 871px;
  box-shadow: 0px 0px 30px 0px #FFFFFF99;
  border-radius: 32px;
  overflow: hidden;
}
.card-img {
  width: 100%;
  height: 100%;
}
.card-title {
  width: 593px;
  height: 142px;
}
.long-btn {
  width: 196px;
  height: 43px;
  margin-top: 20px;
}
.get-btn {
  width: 256px;
  height: 77px;
  margin-top: 20px;
}
.card-close-btn {
  width: 60px;
  height: 60px;
  margin-top: 20px;
}
</style>
