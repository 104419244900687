import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import 'lib-flexible'
import './assets/css/common.css'
import VConsole from 'vconsole';
import { Toast, Button, Area, Popup, Lazyload, Notify  } from 'vant';

Vue.config.productionTip = false

if (process.env.NODE_ENV == 'development') {
  new VConsole({ theme: 'dark' });
}

Vue.use(Button)
Vue.use(Area)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Notify)
Vue.use(Lazyload, {
  lazyComponent: true,
  loading: "/card-unopen.jpg"
});

Vue.prototype.$loading = () => Toast.loading({
  message: '加载中...',
  forbidClick: true,
  loadingType: 'spinner',
  duration: 0
})
Vue.prototype.$loaded = () => Toast.clear()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
