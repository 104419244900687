<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import api from './api'

export default {
  name: 'App',
  async created () {
    // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx223be0d333565e9c&redirect_uri=https%3A%2F%2Faivsion.icu%2F&response_type=code&scope=snsapi_base&state=123#wechat_redirect
    // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx223be0d333565e9c&redirect_uri=https%3A%2F%2Faivsion.icu%2F&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect
    let res = await api.getSign(window.location.href)
    console.log(res, 'getsign res')
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: 'wx223be0d333565e9c', // 必填，公众号的唯一标识
      timestamp: res.msg.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.msg.noncestr, // 必填，生成签名的随机串
      signature: res.msg.signature,// 必填，签名
      jsApiList: [
        'updateAppMessageShareData', 
        'updateTimelineShareData',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'pauseVoice',
        'stopVoice',
        'onVoicePlayEnd',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'translateVoice',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'hideMenuItems',
        'showMenuItems',
        'showAllNonBaseMenuItem',
        'closeWindow',
        'scanQRCode',
        'chooseWXPay',
        'openProductSpecificView',
        'addCard',
        'chooseCard',
        'openCard'
      ] // 必填，需要使用的JS接口列表
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
}
</style>
