<template>
  <section class="card-list">
    <img class="card-bg" src="/card-bg-2.png" />
    <div class="progress">{{nums}}/33</div>
    <!-- <img @click="isShowFill = true" v-if="isShowGet == '1'" class="card-getbtn" src="/card-get.png" /> -->
    <img @click="$router.push('/')" class="back-btn" src="/back.png" />
    <ul class="card-list-main">
        <li v-for="item in cardList" :key="item.cardNum">
            <div class="card-inner">
                <img v-if="item.nums" @click="handleOpenCard(item)" v-lazy="`/card/${item.cardNum}.jpg?v1`" alt="" />
                <img v-else src="/card-unopen.jpg" alt="" />
            </div>
            <div class="card-num" v-if="item.nums" >{{item.nums}}</div>
        </li>
    </ul>
    <div class="card-tips">*实际消耗的卡路里和糖量会根据个人情况有所不同，消耗卡路里换算得出的消耗糖量以卡面上的方糖数字为准，数据仅供参考。</div>
    <video v-show="isShow" id="v2" src="/v2.mp4?v2" playsinline loop preload="auto"></video>
    <CardModal v-if="isShow" @close="handleCloseCard" @fill="isShowFill = true" :cardInfo="cardInfo"  />
    <FillAddress v-if="isShowFill" @close="isShowFill = false"  />
    <!-- <audio id="v2bg" src="/v2-bg.MP3" v-show="false" loop></audio> -->
  </section>
</template>

<script>
import CardModal from '@/components/CardModal'
import FillAddress from '@/components/FillAddress'
import api from "@/api";
import { wxPlay } from '@/utils'

export default {
    components: {
        CardModal,
        FillAddress
    },
    data() {
        return {
            isShow: false,
            isShowFill: false,
            cardList: [],
            cardInfo: {},
            isShowGet: localStorage.getItem("isCanGet"),
            nums: 0,
            hasSSr: false
        }
    },
    methods: {
        handleCloseCard() {
            this.isShow = false
            // const v2bg = document.getElementById("v2bg");
            // v2bg.pause();
            // v2bg.currentTime = 0;
        },
        handleOpenCard(item) {
            console.log(item, 898)
            this.isShow = true
            const v2 = document.getElementById("v2");
            v2.play()
            // wxPlay('v2bg')
            this.cardInfo = { cardNum: item.cardNum, fromCard: true }
        }
    },
    async mounted() {
        this.$loading()
        this.cardList = Array(33).fill(0).map((o, i) => {
            return {
                cardNum: i + 1,
                nums: 0
            }
        })
        const res = await api.card().catch(e => this.$loaded());
        console.log(res);
        // {"result":true,"msg":{"18":"1","32":"1"}}
        if (res.result) {
            const ret = res.msg || {}
            this.nums = Object.keys(ret).length
            // this.hasSSr = !!Object.keys(ret).find(o => o == 33)
            this.cardList = this.cardList.map(o =>{
                o.nums = ret[o.cardNum] || 0
                return o
            })
        } else {
            this.$notify({ type: 'warning', message: res.msg })
        }
        this.$loaded()
    }
}
</script>

<style>
.card-list {
    position: relative;
    width: 100vw;
    height: 5300px;
    background-image: url('/card-bg-1.png');
    overflow: auto;
}
#v2 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 100;
}
.card-list-main {
    position: absolute;
    width: 600px;
    top: 277px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.card-list-main li {
    width: 188px;
    height: 408px;
    margin-bottom: 30px;
    position: relative;
}
.card-list-main li .card-inner {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
}
.card-list-main li img {
    width: 100%;
}
.card-num {
    position: absolute;
    right: -14px;
    top: -14px;
    width: 41px;
    height: 41px;
    background-image: url('/ball.png');
    background-size: 100% 100%;
    z-index: 3;
    color: #fff;
    font-size: 22px;
    text-align: center;
    padding-top: 6px;
    box-sizing: border-box;
}
.card-bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 678px;
    height: 5194px;
}
.progress {
    position: absolute;
    top: 212px;
    right: 94px;
    width: 156px;
    height: 30px;
    line-height: 30px;
    border-radius: 16px;
    background: #8EA7FF;
    z-index: 2;
    text-align: center;
    color: #2F4EC9;
    font-family: Arial;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.88px;
}
.back-btn {
    position: absolute;
    top: 20px;
    left: -5px;
    z-index: 2;
    width: 111px;
    height: 53px;
}
.card-getbtn {
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 2;
    width: 187px;
    height: 70px;
}
.card-tips {
    position: absolute;
    top: 5100px;
    left: 50%;
    transform: translateX(-50%);
    width: 544px;
    z-index: 2;
    margin: 0 auto;
    color: #545964;
    font-family: "PingFang SC";
    font-size: 16px;
    font-weight: 400;
    
}
</style>