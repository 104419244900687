<template>
<div class="home">
    <div @click="handleClick('v11')">播放老的v2</div>
    <div @click="handleClick('v22')">播放新的v2</div>
  <video v-if="isShowV1" id="v11" src="/v2.mp4?v1" loop preload="auto" playsinline></video>
  <video v-if="isShowV2" id="v22" src="/v2.mp4?v1" loop preload="auto" playsinline></video>
</div>
</template>

<script>
export default {
    name: 'home',
    data() {
        return { 
            isShowV1: false,
            isShowV2: false,
        }
    },
    methods: {
        handleClick(id) {
            if (id =='v11') {
                this.isShowV1 = true
                this.isShowV2 = false
            } else {
                this.isShowV1 = false
                this.isShowV2 = true
            }
            const v1 = document.getElementById(id);
            v1.play()
        }
    },
    mounted() {
    }
}
</script>

<style>
.home {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #ccc;
}
#v11 {
    padding: absolute;
    top: 0;
    left:0;
    margin: 0;
    padding: 0;
    width: 100vw;
    z-index: 1;
}
#v22 {
    padding: absolute;
    top: 0;
    left:0;
    margin: 0;
    padding: 0;
    width: 100vw;
    z-index: 10;
}
</style>