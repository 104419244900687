import Vue from 'vue'
import axios from 'axios'

console.log(process.env, 'env')
const req = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  timeout: 30000,
  headers: {'Content-Type': 'application/json; charset=UTF-8'}
})

let requestFn = config => {
  config.headers.Authorization = localStorage.getItem('token')
  return config
}
let requestError = err => {
  Vue.prototype.$toast.fail('网络开小差了,请刷新页面重试')
  console.warn('接口请求异常 >>>>', err)
  return Promise.reject(err)
}

let responseFn = res => {
  let { data } = res
  return data
}

let responseError = err => {
  // 重新登录
  if (err.toString().includes('403')) {
    // 重新登入
    localStorage.removeItem('token')
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx223be0d333565e9c&redirect_uri=https%3A%2F%2Faivsion.icu%2F&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
    return
  }
  Vue.prototype.$toast.fail('网络开小差了,请刷新页面重试')
  console.warn('接口响应异常 >>>>', err)
  return Promise.reject(err)
}

// 请求拦截
req.interceptors.request.use(requestFn, requestError)
// 响应拦截
req.interceptors.response.use(responseFn, responseError)

export default req