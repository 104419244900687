import Vue from "vue";
import VueRouter from "vue-router";
import Wechat from "@/pages/wechat";
import Home from "@/pages/home";
import Index from "@/pages/index.vue";
import CardList from "@/pages/card.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    // {
    //   path: "/",
    //   redirect: "/wechat",
    // },
    { path: "/", component: Index, meta: { title: "第一财经“赢Yi为奥”集卡大比拼"} },
    { path: "/card-list", component: CardList, meta: { title: "我的卡牌"} },
    { path: "/wechat", component: Wechat, meta: { title: "wechat"} },
    { path: "/home", component: Home, meta: { title: "home"}  },
  ],
  base: "/",
});
router.afterEach((to, from) => {
  document.title = to.meta.title;
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  console.log(to, 'toto')
  if (to.path == '/') {
    // 保存分享者token防止网页授权回来找不到
    if (to.query.tk) {
      localStorage.setItem('shareTk', to.query.tk)
    }
    console.log('NODE_ENV', process.env.NODE_ENV)
    if (process.env.NODE_ENV == 'production') {
      if (!to.query.code) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx223be0d333565e9c&redirect_uri=https%3A%2F%2Faivsion.icu%2F&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
        return false
      } 
    }
  }
  next()
});
export default router;
