export const wxPlay = function (id) {
    const UA = window.navigator.userAgent;
    const wechat = UA.includes('MicroMessenger') && !UA.includes('miniProgram')
    const audio = document.getElementById(id);
    if(wechat) {
        if (window.WeixinJSBridge) {
            window.WeixinJSBridge.invoke(
                'getNetworkType',
                {},
                function (e) {
                    audio.play();
                    console.log(id, 'playing111')
                },
                false
            );
        } else {
            document.addEventListener(
                'WeixinJSBridgeReady',
                function () {
                    window.WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
                        audio.play();
                        console.log(id, 'playing22')
                    });
                },
                false
            );
        }
    } else {
        audio.play();
        onsole.log(id, 'playing33')
    }
}